import request from '@/utils/request.js'

// 获取sale数据
export const getSaleList = (page) => {
  return request({
    method: 'GET',
    url: 'content/sale',
    params: page
  })
}
// 获取sale详细数据
export const getSaleInfo = (id) => {
  return request({
    method: 'GET',
    url: 'content/sale/info',
    params: id
  })
}
